.tabbar-container {
    bottom: -10px;
    margin-left: auto;
    margin-right: auto;
}

@media (min-width: 700px) {
    .tabbar-container {
        max-width: 428px; /* 在宽屏设备上设置最大宽度为375px */
        margin-left: auto;
        margin-right: auto;
    }
}