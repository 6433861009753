.library-edit-edit-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: black; /* 设置背景颜色 */
  position: relative;
  background-color: black;
  margin-top: 0px;
}

@media (min-width: 700px) {
  .library-edit-edit-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: black;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-top: 0px;
  }
}

.library-edit-item-media {
  left: 50%;           /* 将元素的左边界移动到容器的50%位置 */
  top: 44px;              /* 如果需要垂直顶部对齐，保留此设置 */
  transform: translateX(-50%); /* 使用transform向左移动元素宽度的50%，从而实现真正的中心对齐 */
  max-width: 100%;     /* 最大宽度为100%，确保元素不会超出容器宽度 */
  max-height: calc(100vh - 370px); /* 最大高度为视口高度减去326px */
  position: absolute;  /* 绝对定位 */
  margin-left: auto;
  margin-right: auto;
}


.library-edit-item-inputbox{
  position: fixed;
  left: 15px;
  padding: 8px;
  width: calc(100vw - 46px);
  height: 81px;
  bottom: 225px;
  border: 1px solid #fff;
  border-radius: 8px;
  color: white;
  left: 50%; /* 设置 left 为视口的 50% */
  transform: translateX(-50%); /* 向左偏移自身宽度的 50% 来居中 */
}

@media (min-width: 700px) {

  .library-edit-item-inputbox{
    position: fixed;
    left: 15px;
    padding: 8px;
    max-width: 362px;
    height: 81px;
    bottom: 225px;
    border: 1px solid #fff;
    border-radius: 8px;
    color: white;
    left: 50%; /* 设置 left 为视口的 50% */
    transform: translateX(-50%); /* 向左偏移自身宽度的 50% 来居中 */
  }
}

.adm-text-area-element{
  color: white;
}


.library-edit-item-upload {
  bottom: 355px;
  width: 250px;
  height: 160px;
  background-color: #212026;
  left: 50%; /* 设置 left 为视口的 50% */
  transform: translateX(-50%); /* 向左偏移自身宽度的 50% 来居中 */
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: fixed;
}

.library-edit-item-upload-image {
  /* background-color: #3C3B3F; */
  width: 32px;
  height: 32px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
}

.library-edit-item-upload-txt {
  margin-top: 29px;
  width: 100%;
  font-size: 12px;
  color: #9d9d9f;
  text-align: center;
}


.library-edit-upload {
  top: 60px;
  width: 100%;
  bottom: 330px;
  position: fixed;
  left: 0;
  background-color: red;
  opacity: 0;
}

.library-edit-go-next {
  position: fixed;
  bottom: 60px;
  width: 124px;
  height: 32px;
  line-height: 32px;
  background-color: #F7B500;
  color: white;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  z-index: 2;
  left: 50%; /* 设置 left 为视口的 50% */
  transform: translateX(-50%); /* 向左偏移自身宽度的 50% 来居中 */
}


.library-edit-main-subitem {
  height: 20px;
  width: 70px;
  left: 50%; /* 设置 left 为视口的 50% */
  transform: translateX(-50%); 
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 22px;
}

.library-edit-main-subitem-option {
  width: 14px;
  height: 14px;
}

.library-edit-main-item-text {
  margin-left: 8px;
  color: white;
  font-size: 14px;
  line-height: 14px;
}

.library-edit-main {
  height: 40px;
  width: 100px;
  left: 50%; /* 设置 left 为视口的 50% */
  transform: translateX(-50%); 
  position: fixed;
  bottom: 180px;
  display: flex;
  flex-direction: column;
}

.library-edit-main-price {
  background-color: #FFFFFF44;
  color: white;
  width: 80px;
  height: 24px;
  left: 50%; /* 设置 left 为视口的 50% */
  transform: translateX(-50%); 
  position: fixed;
  bottom: 110px;
  line-height: 24px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 6px;
  border: 1px solid #fff;
  text-align: center;
}