.mine-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0d1f2b; /* 设置背景颜色 */
  position: relative;
  justify-content: center;
  background-color: black;
}
@media (min-width: 700px) {
  .mine-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    background-color: black;
  }
}

.header-container {
  width: 100%;
  margin-left: 0;
  margin-top: 0;
  position: relative;
  height: 300px;
}

.header-bg {
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  height: auto;
}

.header-name {
  position: absolute;
  left: 99px;
  top: 142px;
  font-size: 24px;
  color: white;
}

.header-id {
  position: absolute;
  left: 99px;
  top: 171px;
  font-size: 14px;
  color: #efefef;
}

.header-user-photo {
  left: 15px;
  top: 133px;
  position: absolute;
  width: 72px;
  height: 72px;
  border-radius: 36px;
}

.header-lnf {
  left: 0;
  top: 225px;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 50px;
}

.header-lnf-cell {
  margin-top: 0;
  margin-left: 24px;
  width: 80px;
  height: 42px;
  font-size: 16px;
  color: white;
}

.header-lnf-cell-text {
  display: flex;
  justify-content: center;
}

.mine-money {
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
}

.mine-money-bg {
  width: 100%;
  height: 100%;
}

.mine-money-title {
  left: 16px;
  top: 14px;
  position: absolute;
  color: white;
  font-size: 12px;
}

.mine-money-coin {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 16px;
  top: 35px;
}

.mine-money-number {
  left: 49px;
  top: 35px;
  position: absolute;
  color: white;
  font-size: 20px;
}

.mine-money-withdraw {
  position: absolute;
  top: 25px;
  left: 182px;
  width: 61px;
  height: 24px;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 24px;
  background: linear-gradient(
    269.52deg,
    rgba(56, 57, 57, 0) 0.4%,
    #555555 100.44%
  );
  display: flex;
  flex-direction: row;
}

.mine-money-topup {
  position: absolute;
  top: 25px;
  right: 12px;
  width: 61px;
  height: 24px;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 24px;
  background: linear-gradient(
    269.52deg,
    rgba(56, 57, 57, 0) 0.4%,
    #555555 100.44%
  );
  display: flex;
  flex-direction: row;
}

.mine-money-btn-txt {
  margin-left: 8px;
}

.mine-money-mini-arrow {
  margin: 8px;
  width: 6px;
  height: 6px;
}
.mine-list {
  margin-top: 15px;
  margin-left: 18px;
  margin-right: 18px;
  height: 404px;
  background-color: #ffffff17;
}

.mine-list-item {
  margin-bottom: 1px;
  width: 100%;
  height: 52px;
  background-color: black;
  display: flex;
  flex-direction: row;
  position: relative;
}

.mine-list-item-img {
  width: 24px;
  margin-top: 19px;
}

.mine-list-item-title {
  font-size: 16px;
  color: white;
  margin-left: 15px;
  margin-top: 19px;
}

.mine-list-item-arrow {
  top: 18px;
  right: 0px;
  width: 24px;
  height: 24px;
  position: absolute;
}

.mine-logout {
  margin: 60px auto 0;
  width: 124px;
  height: 32px;
  background-color: #d9d9d933;
  font-size: 16px;
  color: white;
  line-height: 32px;
  text-align: center;
}

.mine-black {
  width: 100%;
  height: 160px;
  background-color: black;
}

