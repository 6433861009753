.personal-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0d1f2b; /* 设置背景颜色 */
  position: relative;
  justify-content: center;
  background-color: black;
}

@media (min-width: 700px) {
  .personal-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    background-color: black;
  }
}

.personal-title {
  margin: 20px;
  margin-top: 54px;
  font-size: 14px;
  color: #bdbdbf;
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;  /* 水平居中 */
  color: white;

}

.personal-item-title {
  width: 150px;
}

.personal-title-edit {
  display: flex;
  flex-direction: row;
  justify-content: center;  /* 水平居中 */
}

.personal-title-input {
  border: 0px;
  background-color: #FFFFFF00;
  color: white;
  font-size: 24px;
  text-align: center;
  width: 80px;
}

.personal-header {
  margin-top: 33px;
  margin-left: auto;
  margin-right: auto;
  width: 72px;
  height: 72px;
  border-radius: 36px;
}

.personal-header-edit {
  margin-left: 5px;
  margin-top: 9px;
  width: 16px;
  height: 16px;
}

.personal-item {
  margin-left: 15px;
  margin-right: 15px;
  height: 54px;
  font-size: 16px;
  color: white;
  line-height: 54px;
  border-bottom: 1px solid #1a191d;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
}

.personal-input {
  background-color: rgba(0, 0, 0, 0);
  border: none; /* 移除边框 */
  outline: none; /* 移除获取焦点时的默认边框 */
  margin-left: 15px;
  margin-right: 15px;
  width: 260px;
  text-align: right;
  font-size: 16px;
  color: white;
}

.personal-item-arrow {
  top: 20px;
  right: 15px;
  position: absolute;
  width: 20px;
  height: 20px;
}

.personal-item-video {
  margin-left: 15px;
  margin-right: 15px;
  height: 54px;
  font-size: 16px;
  color: white;
  line-height: 54px;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
}

.personal-item-content {
  margin-right: 40px;
  margin-top: 2px;
}

.personal-item-upload {
  width: 230px;
  height: 160px;
  background-color: #212026;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.personal-item-upload-image {
  /* background-color: #3C3B3F; */
  width: 32px;
  height: 32px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
}

.personal-item-upload-txt {
  margin-top: 29px;
  width: 100%;
  font-size: 12px;
  color: #9d9d9f;
  text-align: center;
}

.personal-next {
  width: 124px;
  height: 32px;
  line-height: 32px;
  background-color: #2e2d30;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  text-align: center;
}

.personal-upload {
  width: 230px;
  height: 160px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.personal-upload-video {
  width: 230px;
  height: 160px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #2e2d30;
}
