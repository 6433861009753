.prelive-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: black; /* 设置背景颜色 */
  position: relative;
  color: white;
}

@media (min-width: 700px) {
  .prelive-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: black;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
  }
}

.prelive-live-container {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.prelive-main {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
}

.prelive-share-btn {
  position: absolute;
  top: 9px;
  right: 10px;
  width: 20px;
  height: 20px;
  z-index: 101;
}

.prelive-main-info {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 76px;
  height: 88px;
  position: relative;
  background-color: #222222;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
}

.prelive-main-info-text1 {
  margin-top: 18px;
  font-size: 16px;
  display: flex;
  flex-direction: row;
}

.prelive-main-info-input {
 width: 100px;
  height: 18px;
  z-index: 2;
}

.adm-input-element {
  color: white;
}

.prelive-main-info-text2 {
  font-size: 12px;
  margin-top: 11px;
  display: flex;
  flex-direction: row;
}

.prelive-main-info-text1-img {
  margin-left: 7px;
  margin-top: 3px;
  width: 16px;
  height: 16px;
}

.prelive-main-info-text2-img {
  margin-left: 3px;
  width: 18px;
  height: 18px;
}

.prelive-main-info-image {
  margin: 12px;
  width: 64px;
  height: 64px;
  border-radius: 3px;
}

.prelive-main-info-edit {
  position: absolute;
  width: 64px;
  bottom: 10px;
  left: 12px;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
  background-color: #00000066;
}

.prelive-main-item {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 13px;
  height: 32px;
  line-height: 32px;
  position: relative;
  background-color: #222222;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
}

.prelive-main-item-option {
  margin-top: 11px;
  margin-left: 14px;
  width: 10px;
  height: 10px;
  padding-right: 14px;
  position: relative;
}

.prelive-main-item-text {
  width: 150px;
  position: relative;
}

.prelive-main-subitem {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 2px;
  height: 32px;
  line-height: 32px;
  position: relative;
  background-color: #222222;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
}

.prelive-main-subitem-option {
  margin-top: 11px;
  margin-left: 34px;
  width: 10px;
  height: 10px;
  padding-right: 14px;
  position: relative;
}

.prelive-main-tool {
  left: 50%;                       /* Position the left edge of the element at the center of the viewport */
  transform: translateX(-50%);     /* Shift the element left by half its own width to center it */
  width: 303px;
  bottom: 173px;
  height: 88px;
  position: fixed;
  display: flex;
  flex-direction: row;
  color: white;
  text-align: center;
  justify-content: space-between;
}

.prelive-main-tool-camera {
  margin-top: 1.5px;
  width: 24px;
  height: 21px;
  margin-left: auto;         /* 左边距自动 */
  margin-right: auto;   
}
.prelive-main-tool-beauty {
  margin-top: 1px;
  width: 24px;
  height: 22px;
  margin-left: auto;         /* 左边距自动 */
  margin-right: auto;   
}
.prelive-main-tool-kind {
  margin-top: 0.5px;
  width: 24px;
  height: 23px;
  margin-left: auto;         /* 左边距自动 */
  margin-right: auto;   
}
.prelive-main-tool-setting {
  width: 24px;
  height: 24px;
  margin-left: auto;         /* 左边距自动 */
  margin-right: auto;   
}
.prelive-main-tool-more {
  margin-top: 10px;
  padding-left: 5px;
  padding-bottom: 12px;
  width: 16px;
  height: 4px;
  margin-left: auto;         /* 左边距自动 */
  margin-right: auto;   
}

.prelive-main-tool-text {
  margin-top: 10px;
  width: 100%;
  text-align: center;
}

.prelive-live-start {
  position: fixed;
  bottom: 95px;
  background-color: #0066ff;
  width: 147px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  font-size: 16px;
  color: white;
  border-radius: 16px;
  left: 50%;                       /* Position the left edge of the element at the center of the viewport */
  transform: translateX(-50%); 
}

