.price-alertcontainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  text-align: center;
  z-index: 2;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 100vh;
}

@media (min-width: 700px) {
  .price-alertcontainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 428px;
    position: absolute;
    z-index: 2;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    height: 100vh;
    text-align: center;
  }
}

.price-alertbg {
  width: 320px;
  height: 382px;
  background-size: cover; /* 背景图覆盖整个元素 */
  background-position: center; /* 背景图居中显示 */
  position: absolute;
}

.price-alertmain {
  width: 320px;
  height: 382px;
  background-size: cover; /* 背景图覆盖整个元素 */
  background-position: center; /* 背景图居中显示 */
  display: flex;
  flex-direction: column;
  position: relative;
}


.price-alertmain-top-img {
  margin-top: 0px;
  margin-left: 124px;
  width: 72px;
  height: 72px;
}

.price-alertmain-price {
  margin-top: 13px;
  margin-left: 28px;
  margin-right: 28px;
  height: 192px;
  display: flex;
  flex-direction: row;
  justify-content:  space-between;
  flex-wrap: wrap;
}

.price-alertmain-price-item {
  width: 80px;
  height: 90px;
  border: 1px solid #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.price-alertmain-price-item1{
  background-color: transparent; /* 去掉背景色 */
  border: none; /* 去掉边框 */
  outline: none; 
  width: 37px;
  height: 20px;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 18px;
  font-size: 16px;
  background-color: #313030;
  text-align: center;
  color: white;

}
.price-alertmain-price-item2{
  background-color:#393838;
  margin: 10px;
  height: 1px;
}
.price-alertmain-price-item3{
  color: white;
  height: 21px;
  line-height: 21px;
  width: 100%;
  font-size: 14px;
}

.price-alertmain-top-title {
  width: 100%;
  height: 22px;
  line-height: 22px;
  font-size: 16px;
  color: white;
}

.price-alertmain-sure {
  margin-left: 98px;
  margin-top: 26px;
  width: 124px;
  height: 32px;
  background-color: #F7B500;
  color:  black;
  font-size: 16px;
  line-height: 32px;
  border-radius: 6px;
}



