.navigation-bar {
  position: fixed;
  left: 0;
  top: 0;
  height: 44px;
  z-index: 100;
}

@media (min-width: 700px) {
  .navigation-bar {
    position: absolute;
    top: 0; /* 可以调整为需要的位置 */
    left: 50%; /* 左边界从父元素中心开始 */
    transform: translateX(-50%); /* 左移自身宽度的50%，从而实现居中 */
    height: 44px;
    max-width: 428px;
    z-index: 100;
  }
}

.navigation-back {
  position: absolute;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.navigation-more {
  position: absolute;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  right: 15px;
  top: 10px;
}


.navigation-title-container {
  width: 100vw;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}

@media (min-width: 700px) {

  .navigation-title-container {
    width: 100vw;
    max-width: 428px;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
  }
}


.navigation-title {
  font-size: 16px;
  color: white;
  height: 44px;
  line-height: 44px;
  text-align: center;
}
