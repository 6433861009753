.report-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0d1f2b; /* 设置背景颜色 */
  position: relative;
  justify-content: center;
  background-color: black;
}

@media (min-width: 700px) {
  .report-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    background-color: black;
  }
}

.report-title {
  color: black;
  font-size: 16px;
  margin-left: 15px;
  margin-top: 30px;
}

.report-list {
  margin-top: 44px;
  background-color: #fff;
  height: 100vh;
  padding-bottom: 20px;
}

.report-list-item {
  display: flex;
  flex-direction: row;
  height: 56px;
  position: relative;
  margin-left: 32px;
  margin-right: 32px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
  font-weight: 800;
}
.report-list-item-checkbox {
  margin-top: 21px;
  width: 14px;
  height: 14px;
}

.report-list-item-txt {
  margin-top: 21px;
  margin-left: 8px;
  line-height: 14px;
}
.report-list-submit {
  margin-top: 150px;
  background-color: #f0b429;
  width: 132px;
  height: 35px;
  color: white;
  text-align: center;
  line-height: 35px;
  font-size: 16px;
  border-radius: 4px;
  margin-left: auto; /* Center horizontally */
  margin-right: auto; /* Center horizontally */
  display: block; /* Ensure the button behaves as a block element */
}
