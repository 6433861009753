.find-edit-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh-44px);
  background-color: #000;
  padding-bottom: 44px;
}

@media (min-width: 700px) {
  .find-edit-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh-44px);
    background-color: #000;
    margin-left: auto;
    margin-right: auto;
    max-width: 428px;
  }
}

.find-edit-search-input {
  margin-top: 63px;
  height: 34px;
  border: 0px;
  border-radius: 5px;
  margin-left: 15px;
  margin-right: 15px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  font-size: 14px;
  border: 1px solid white;
  background-color: black;
  border-radius: 4px;
}

.find-edit-list {
  margin-top: 44px;
  height: 400px;
}

.find-edit-item {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 12px;
  height: 140px;
  position: relative;
  overflow: hidden;
}

.find-edit-item-absolute {
  left: 15px;
  right: 15px;
  top: 56px;
  height: 140px;
  position: absolute;
  background-color: red;
  opacity: 0;
}

.find-edit-item-bg {
  border-radius: 8px;
}

.find-edit-item-head {
  position: absolute;
  border-radius: 44px;
  width: 88px;
  height: 88px;
  left: 31px;
  top: 28px;
  overflow: hidden;
  border: 2px solid white;
}

.find-edit-item-head-dot {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 95px;
  top: 100px;
  border-radius: 8px;
  background-color: green;
  border: 1px solid white;
}

.dot-unable {
  background-color: #565656;
}

.find-edit-item-name {
  position: absolute;
  left: 135px;
  top: 64px;
  font-size: 16px;
  color: white;
}

.find-edit-item-id {
  position: absolute;
  left: 135px;
  top: 92px;
  font-size: 12px;
  color: white;
  background: #0000006e;
  padding: 6px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}

.find-edit-item-id-star {
  margin-left: 4px;
  width: 16px;
  height: 16px;
}

.find-edit-item-state {
  position: absolute;
  right: 29px;
  top: 14px;
  padding: 6px;
  color: white;
  background: #0000006e;
  padding: 6px;
  border-radius: 3px;
}

.find-edit-item-more {
  position: absolute;
  right: 29px;
  bottom: 14px;
  padding: 6px;
}
