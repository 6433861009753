.success-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0d1f2b; /* 设置背景颜色 */
  position: relative;
  justify-content: center;
  background-color: black;
  text-align: center;
}

@media (min-width: 700px) {
  .success-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    background-color: black;
    
  }
}

.success-title-pay {
  margin-top: 122px;
  display: flex;
  flex-direction: row;
  margin-left:  auto; /* 左右自动居中 */
  margin-right:  auto;
}

.success-title-begin {
  
  font-size: 20px;
  color: white;
}

.success-logo {
  color: white;
  margin-left: 3px;
  width: 28px;
  height: 28px;
}

.success-sure {
  margin-top: 172px;
  font-size: 20px;
  color: white;
  background-color: #0053A0;
  width: 245px;
  height: 34px;
  line-height: 34px;
  border-radius: 30px;
  margin-left:  auto; /* 左右自动居中 */
  margin-right:  auto;
}

.success-cancel {
  margin-top: 14px;
  font-size: 16px;
  color: white;
}


.success-order {
  margin-top: 30px;
  font-size: 10px;
  color: white;
}

.success-order-time {
  margin-top: 10px;
  font-size: 12px;
  color: white;
}