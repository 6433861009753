.home {
  height: 100%;
  width: 100%;
  background-color: black;
  overflow: hidden;
  padding-bottom: 44px;
}

@media (min-width: 700px) {
  .home {
    height: 100%;
    width: 100%;
    background-color: black;
    overflow: hidden;
    max-width: 428px; /* 常见的手机屏幕宽度 */
  }
}

.home-top-navbar {
  height: 44px;
  width: 100%;
  position: fixed;
}

.home-main {
  margin-top: 50px;
  height: 100%;
  width: 100%;
}

.home-tabs {
  margin-top: 0px;
  height: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.home-tabs-item {
  margin-left: 20px;
  display: flex;
  color: white;
  font-size: 15px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  position: relative;
}

.home-tabs-item-txt {
  height: 32px;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  padding-left: 10px;
  padding-right: 10px;
}

.home-tabs-item-bg {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.home-tabs-item-bg-left {
  position: absolute;
  height: 32px;
  left: 0;
}

.home-tabs-item-bg-right {
  position: absolute;
  height: 32px;
  right: 0;
}

.home-selected {
  color:  #F7B500;
}

.home-live {
  margin-top: 0px;
  margin-left: 12px;
  margin-right: 20px;
  height: 102px;
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
}

.home-live-item {
  position: relative;
  margin-top: 16px;
  width: 62px;
  height: 83px;
  margin-left: 0px;
  margin-right: 20px;
}

.home-live-item-outside-image {
  width: 62px;
  height: 62px;
  border-radius: 31px;
  border: 2px solid #F7B500;
}

.home-live-item-image {
  width: 58px;
  height: 58px;
  border-radius: 29px;
  border: 2px solid black;
}

.home-live-item-nickname {
  font-size: 12px;
  color: white;
  text-align: center;
  margin-top: 5px;
  width: 100%;
  /* overflow: hidden; */
}

.home-live-item-tag {
  top: 55px;
  left: 15px;
  position: absolute;
  width: 38px;
  height: 14px;
}

.home-list {
  background-color: black;
}

.home-item {
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 15px;
  height: 616px;
  background-color: black;
  position: relative;
}

.home-bg {
  top: 0;
  left: 0;
  height: 616px;
  background-color: black;
}

.home-item-id {
  position: absolute;
  left: 31px;
  top: 25px;
  font-size: 12px;
  color: white;
  background: #0000006E;
  padding: 6px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}

.home-item-online {
  position: absolute;
  top: 53px;
  right: 0px;
  width: 64px;
  height: 32px;
  color: #fff;
  line-height: 32px;
  text-align: center;
}

.home-item-id-star{
  margin-left: 4px;
  width: 16px;
  height: 16px;
}

.home-item-hot {
  position: absolute;
  top: 30px;
  right: 24px;
  width: 32px;
  height: 20px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* Standard */
}

.home-item-main {
  position: absolute;
  width: 100%;
  height: 292px;
  bottom: -5px;
  left: 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
  user-select: none; /* Standard */
}

.home-item-name {
  position: absolute;
  left: 77px;
  bottom: 107px;
  font-size: 24px;
  color: white;
}

.home-item-enter {
  position: absolute;
  left: 25px;
  bottom: 58px;
  font-size: 14px;
  border: 1px solid #fff; /* 设置边框为2像素宽的白色边框 */
  color: #fff;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  height: 28px;
  display: flex; /* 使用 flex 布局 */
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
}

.home-item-head {
  position: absolute;
  left: 25px;
  bottom: 103px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #fff; 
}

.home-item-star {
  position: absolute;
  left: 30px;
  bottom: 40px;
  display: flex;
  flex-direction: row;
  width: 150px;
  height: 30px;
  align-items: center; /* 垂直居中 */
  justify-content: left; /* 水平居中 */
}

.home-item-number {
  position: absolute;
  right: 30px;
  bottom: 40px;
  display: flex;
  flex-direction: row;
  width: 150px;
  height: 30px;
  align-items: center; /* 垂直居中 */
  justify-content: right; /* 水平居中 */
}

.home-item-1v1-invite {
  width: 72px;
  height: 72px;
  position: absolute;
  right: 19px;
  bottom: 55px;
}

.home-item-star-logo {
  width: 20px;
  height: 20px;
}

.home-item-number-logo {
  width: 24px;
  height: 24px;
}

.home-item-txt1 {
  margin-left: 2px;
  font-size: 16px;
  color: #f7b500;
}

.home-item-txt2 {
  margin-left: 2px;
  font-size: 16px;
  color: #fff;
}

.home-list-empty {
  margin-top: 200px;
}

