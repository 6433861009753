.my-info-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0d1f2b; /* 设置背景颜色 */
  position: relative;
  justify-content: center;
  background-color: black;
}
@media (min-width: 700px) {
  .my-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    background-color: black;
  }
}

.my-info-header-container {
  width: 100%;
  left: 0;
  top: 0;
  height: 300px;
}

.my-info-header-bg {
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  height: auto;
}

.my-info-header-name {
  position: absolute;
  left: 99px;
  top: 142px;
  font-size: 24px;
  color: white;
}

.my-info-header-id {
  position: absolute;
  left: 99px;
  top: 161px;
  font-size: 14px;
  color: #efefef;
  display: flex;
  flex-direction: row;
}

.my-info-header-des {
  position: absolute;
  left: 16px;
  top: 215px;
  font-size: 14px;
  color: #efefef;
}

.my-info-main-info-input {
  width: 220px;
  height: 18px;
  z-index: 2;
}

.my-info-header-logo {
  margin-right: 6px;
  width: 20px;
}
.my-info-header-star {
  position: absolute;
  top: 156px;
  right: 106px;
  height: 20px;
  display: flex;
  flex-direction: row;
  color: #f7b500;
  font-size: 16px;
}

.my-info-item-lock-container{
  left: 0;
  top: 0;
}

.my-info-item-photo-lock {
  margin-top: 184px;        /* 元素距离顶部的距离 */
  width: 32px;              /* 元素的宽度 */
  height: 32px;             /* 元素的高度 */
  left: 50%;                /* 元素左边界相对于父元素的中心 */
  top: 0;                   /* 保持元素在容器的顶部 */
  transform: translateX(-50%); /* 向左偏移元素自身宽度的50%，以实现精确居中 */
  position: absolute;       /* 使用绝对定位 */
}

.my-info-item-photo-money {
  left: 50%;                /* 元素左边界相对于父元素的中心 */
  top: 230px;                   /* 保持元素在容器的顶部 */
  transform: translateX(-50%); 
  height: 28px;
  border-radius: 4px;
  background-color: #f7b500;
  position: absolute;
  display: flex;
  flex-direction: row;
  line-height: 28px;
  z-index: 100;
}

.my-info-header-like {
  position: absolute;
  top: 156px;
  right: 15px;
  height: 20px;
  display: flex;
  flex-direction: row;
  color: #f7b500;
  font-size: 16px;
}

.my-info-header-user-photo {
  left: 15px;
  top: 133px;
  position: absolute;
  width: 72px;
  height: 72px;
  background-color: white;
  border-radius: 36px;
}

.my-info-header-user-photo-input {
  left: 15px;
  top: 133px;
  position: absolute;
  width: 78px;
  height: 78px;
  background-color: white;
  border-radius: 36px;
  opacity: 0;
}

.my-info-header-user-photo-camera {
  left: 65px;
  top: 173px;
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 12px;
  padding: 3px;
}

.my-info-header-lnf {
  left: 0;
  top: 251px;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 50px;
}

.my-info-header-lnf-cell {
  margin-top: 0;
  margin-left: 15px;
  width: 24px;
  height: 24px;
}

.my-info-header-notice {
  width: 56px;
  height: 28px;
  position: absolute;
  right: 15px;
  top: 249px;
  color: white;
  font-size: 16px;
  border-radius: 14px; /* 设置圆角大小为 10px */
  border: 1px solid #ffffff; /* 设置白色边框，边框宽度为 2px */
  text-align: center;
  line-height: 28px;
}

.my-info-header-change-bg {
  width: 66px;
  height: 42px;
  position: absolute;
  right: 15px;
  top: 249px;
  color: white;
  font-size: 16px;
  text-align: center;
  line-height: 17px;
}

.my-info-header-change-input {
  width: 66px;
  height: 42px;
  position: absolute;
  right: 15px;
  top: 249px;
  color: white;
  font-size: 16px;
  text-align: center;
  line-height: 17px;
  opacity: 0;
}

.my-info-header-change-bg-image {
  margin-left: 21px;
  width: 24px;
  height: 24px;
}

.my-info-media {
  margin-top: 15px;
}

.my-info-list {
  margin-left: 2px;
  margin-right: 2px;
}

.my-info-list-item {
  margin-bottom: 1px;
  width: 100%;
  max-height: 413px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.my-info-list-item-img {
  margin-left: 0px;
  margin-top: 20px;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border: 1px solid white;
  object-fit: cover;
}

.my-info-list-item-right {
  display: flex;
  flex-direction: column;
  margin-right: 0px;
  width: 90%;
}

.my-info-list-item-title {
  font-size: 16px;
  color: white;
  height: 20px;
  margin-left: 15px;
  width: 300px;
  margin-top: 19px;
}

.my-info-list-item-bigpic {
  margin-top: 12px;
  margin-left: 15px;
  width: calc(100%-15px);
  height: 290px;
}

.my-info-list-item-number {
  color: white;
  font-size: 16px;
  height: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  line-height: 20px;
  padding-top: 15px;
}

.my-info-list-item-number-logo1 {
  margin-left: 10px;
  width: 20px;
  height: 20px;
  padding-right: 5px;
}

.my-info-list-item-number-logo2 {
  width: 20px;
  height: 18px;
  margin-left: 50px;
  padding-right: 5px;
}

.adm-tabs-tab-active {
  color: white;
  font-size: 16px;
}

.adm-tabs-header-mask-right {
  background-color: black;
}

.adm-tabs-tab {
  color: white;
  font-size: 16px;
}

.adm-tabs-tab-line {
  background-color: white;
}

.adm-tabs-header {
  border-bottom-width: 0;
  padding-left: 60px;
  padding-right: 60px;
}

.my-library-add {
  bottom: 50px;
  left: 0;
  right: 0;
  width: 50px;
  height: 50px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  z-index: 200;
}

.my-video-style {
  position: absolute;
  width: 80%;
  left: 50%; /* Centering horizontally relative to the nearest positioned ancestor */
  top: 50%;
  transform: translate(-50%, -50%);
}
