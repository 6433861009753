.face-alert-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  text-align: center;
  z-index: 2;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 100vh;
}

@media (min-width: 700px) {
  .face-alert-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 428px;
    position: absolute;
    z-index: 2;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    height: 100vh;
    text-align: center;
  }
}

.face-alert-bg {
  width: 320px;
  height: 382px;
  background-size: cover; /* 背景图覆盖整个元素 */
  background-position: center; /* 背景图居中显示 */
  position: absolute;
}

.face-alert-main {
  width: 320px;
  height: 382px;
  background-size: cover; /* 背景图覆盖整个元素 */
  background-position: center; /* 背景图居中显示 */
  display: flex;
  flex-direction: column;
  position: relative;
}


.face-alert-main-top-img {
  margin-top: 0px;
  margin-left: 124px;
  width: 72px;
  height: 72px;
}

.face-alert-main-price {
  margin-top: 13px;
  margin-left: 28px;
  margin-right: 28px;
  height: 192px;
  display: flex;
  flex-direction: row;
  justify-content:  space-between;
  flex-wrap: wrap;
}

.face-alert-main-price-item {
  width: 120px;
  height: 120px;
  border: 1px solid #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.face-alert-main-price-item1{
  width: 40px;
  height: 40px;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 18px;
  font-size: 16px;
  background-color: #313030;
  text-align: center;
  color: white;
}

.face-alert-main-price-item-image{
  max-width: 40px;
  height: 40px;
  line-height: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 18px;
  font-size: 16px;
  background-color: #313030;
  text-align: center;
  color: white;
}

.face-alert-main-price-item2{
  background-color:#393838;
  margin: 10px;
  height: 1px;
}
.face-alert-main-price-item3{
  color: white;
  height: 21px;
  line-height: 21px;
  width: 100%;
  font-size: 14px;
}

.face-alert-main-top-title {
  width: 100%;
  height: 22px;
  line-height: 22px;
  font-size: 16px;
  color: white;
}

.face-alert-main-bottom-notice {
  width: 100%;
  height: 22px;
  line-height: 22px;
  font-size: 16px;
  color: white;
}


.face-alert-main-sure {
  margin-left: 98px;
  margin-top: 6px;
  width: 124px;
  height: 32px;
  background-color: #F7B500;
  color:  black;
  font-size: 16px;
  line-height: 32px;
  border-radius: 6px;
}

.face-alert-add-image {
  width: 120px;
  height: 120px;
  position: absolute;
  left: 28px;
  background-color: yellow;
  opacity: 0;
}

.face-alert-add-video{
  width: 120px;
  height: 120px;
  right: 28px;
  position: absolute;
  background-color: yellow;
  opacity: 0;
}