.income-container {
  display: flex;
  flex-direction: column;
  width: 100%;
 
  position: relative;
  justify-content: center;
  background-color: black;
}

@media (min-width: 700px) {
  .income-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    background-color: black;
  }
}


 
.income-title-pay {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-left:  auto; /* 左右自动居中 */
  margin-right:  auto;
}

.income-title-begin {
  
  font-size: 16px;
  color: white;
}

.income-title-price {
 
  font-size: 20px;
  color: white;
}

.income-logo {
 
  font-size: 20px;
  color: white;
  margin-left: 3px;
  width: 30px;
  height: 30px;
}