.unclosed-alert-container {
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100%;
  position: fixed;
  text-align: center;
  z-index: 2;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 100vh;
  
}

@media (min-width: 700px) {
  .unclosed-alert-container {
    display: flex;
    flex-direction: column;
    top: 0;
    width: 100%;
    max-width: 428px;
    position: fixed;
    z-index: 2;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    height: 100vh;
    text-align: center;
  }
}

.unclosed-alert-bg {
  width: 320px;
  height: 341px;
  background-size: cover; /* 背景图覆盖整个元素 */
  background-position: center; /* 背景图居中显示 */
  position: absolute;
}

.unclosed-alert-main {
  width: 320px;
  height: 282px;
  background-size: cover; /* 背景图覆盖整个元素 */
  background-position: center; /* 背景图居中显示 */
  display: flex;
  flex-direction: column;
  position: relative;
}


.unclosed-alert-main-top-img {
  margin-top: 0px;
  margin-left: 124px;
  width: 72px;
  height: 72px;
  border-radius: 36px;
}

.unclosed-alert-main-price {
  margin-top: 13px;
  margin-left: 28px;
  margin-right: 28px;
  height: 92px;
  display: flex;
  flex-direction: row;
  justify-content:  space-between;
  flex-wrap: wrap;
  color:  white;
  font-size: 16px;
  overflow: hidden;
}

.unclosed-alert-main-top-title {
  width: 100%;
  height: 22px;
  line-height: 22px;
  font-size: 16px;
  color: white;
}

.unclosed-alert-main-sure {
  margin-left: 98px;
  margin-top: 26px;
  width: 124px;
  height: 32px;
  background-color: #F7B500;
  color:  black;
  font-size: 16px;
  line-height: 32px;
  border-radius: 6px;
}

.unclosed-alert-main-close {
  margin-left: 98px;
  margin-top: 13px;
  width: 124px;
  height: 32px;
  color:  white;
  font-size: 16px;
  line-height: 32px;
  border-radius: 6px;
}


.unclosed-alert-main-bottom-txt {
  margin-top: 20px;
  width: 100%;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
  color: white;
}

