.liveoutput-chat-container {
  width: 94%;
  height: 200px;
  left: 3%;
  bottom: 140px;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.live-chat-name {
  color: #f7b500;
  border-top-left-radius: 20px; /* 左上角圆角 */
  border-bottom-left-radius: 20px; /* 左下角圆角 */
  background-color: rgba(0, 0, 0, 0.2);
  padding-left: 5px;
}

.live-chat-content {
  color: white;
  border-top-right-radius: 20px; /* 左上角圆角 */
  border-bottom-right-radius: 20px; /* 左下角圆角 */
  background-color: rgba(0, 0, 0, 0.2);
  padding-right: 20px;
}

::-webkit-scrollbar {
  display: none;
}

.live-chat-scroll-item {
  margin-top: 5px;
  margin-left: 5px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
}