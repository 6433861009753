.face-code-container {
  top: 100px;
  left: 10%;
  display: flex;
  flex-direction: column;
  width: 80%;
  position: fixed;
  text-align: center;
  z-index: 2;
  height: 180px;
  background-color: #FF0087;
  border-radius: 8px;
}

.face-code-main-title {
  color:  white;
  font-size: 20px;
  font-weight: 1000;
}

 .face-code-main-code {
  margin-top: 10px;
  color:  white;
  font-size: 40px;
 }
 
.face-code-main-bottom-margin {
  margin-top: 10px;
}

.face-code-main-bottom-txt{
  width: 100%;
  height: 22px;
  line-height: 22px;
  font-size: 12px;
  color: white;
}

