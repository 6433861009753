.setting-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #0d1f2b; /* 设置背景颜色 */
  position: relative;
  background-color: black;
}

@media (min-width: 700px) {
  .setting-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background-color: black;
  }
}

.setting-list {
  margin-top: 44px;
  padding-bottom: 20px;
  border-radius: 8px;
}


.setting-title {
  margin: 20px;
  margin-top: 54px;
  font-size: 14px;
  color: #bdbdbf;
}

.setting-item {
  margin-left: 15px;
  margin-right: 15px;
  height: 54px;
  font-size: 16px;
  color: white;
  line-height: 54px;
  border-bottom: 1px solid #1a191d;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
}

.setting-item-content {
  margin-right: 25px;
  margin-top: 2px;
}

.setting-item-arrow {
  top: 20px;
  right: 0px;
  position: absolute;
  width: 20px;
  height: 20px;
}

.setting-bottom {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 12px;
  position: absolute;
  bottom: 86px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center; /* 水平居中 */
  align-items: center;   
}

.setting-bottom-right {
  padding-left: 5px;
  color: #0B99FF;
}