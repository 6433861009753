.navbar {
  height: 44px;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  background-color: black;
}

@media (min-width: 700px) {
  .navbar {
    height: 44px;
    width: 100%;
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    background-color: black;
    margin-left: auto;
    margin-right: auto;
    max-width: 428px;
  }
}

.bottom {
  background-color: #020105;
  bottom: 0;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  position: fixed;
  width: 100vw;
  z-index: 1000;
}

.btn-contain {
  display: flex;
  flex-direction: row;
}

.head-btn {
  margin-left: 15px;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  border: 1px solid black;
}

.search-btn {
  margin-right: 15px;
  width: 20px;
  height: 20px;
}

.history-btn {
  margin-right: 15px;
  width: 20px;
  height: 20px;
}

.message-btn {
  margin-right: 15px;
  width: 20px;
  height: 20px;
}
