.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #0d1f2b;
}

@media (min-width: 700px) {
 
  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
  }
}

.resetpwd-title {
  text-align: center; 
  font-size: 20px;
  color: white;
  justify-content:  center;
  margin-top: 140px;
}

.resetpwd-email{
  margin-top: 40px;
  margin-left: 60px;
  margin-right: 60px;
  border: 0px;
  border-radius: 5px;
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #11354D;
  font-size: 14px;
  color: white;
}

.resetpwd-send-email{
  margin-top: 40px;
  margin-left: 66px;
  margin-right: 66px;
  height: 34px;
  border: none;
  color: white;
  border-radius: 30px;
  background-color: #0053A0;
  font-size: 16px;
}

.resetpwd-cancel{
  margin-top: 20px;
  height: 34px;
  font-size: 16px;
  color: white;
  text-align: center; 
}


::-webkit-scrollbar {
  display: none;
}
