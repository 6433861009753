.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #0d1f2b; /* 设置背景颜色 */
}


@media (min-width: 700px) {
 
  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
  }
}

.emailcode-title {
  margin-top: 122px;
  color: white;
  font-size: 20px;
  text-align: center;
  padding-bottom: 44px;
}

.emailcode-des {
  margin-top: 14px;
  color: white;
  font-size: 14px;
  text-align: left;
  margin-left: 60px;
  margin-right: 60px;
  line-height: 24px;
}

.emailcode-input {
  margin-top: 12px;
  margin-left: 60px;
  margin-right: 60px;
  border: 0px;
  border-radius: 8px;
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #11354d;
  font-size: 14px;
  color: white;
}

.emailcode-login {
  margin-top: 80px;
  margin-left: 66px;
  margin-right: 66px;
  height: 34px;
  border: none;
  color: white;
  border-radius: 8px;
  background-color: #0053a0;
  font-size: 16px;
}

.emailcode-check-text {
  color: white;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  flex-wrap: wrap;
  display: inline-block;
  margin-left: 45px;
  margin-right: 45px;
  margin-top: 20px;
  text-align: center;
}

.emailcode-check-text-privacy {
  color: #0e95e9;
  font-size: 14px;
}

::-webkit-scrollbar {
  display: none;
}