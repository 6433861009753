.history-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0d1f2b; /* 设置背景颜色 */
  position: relative;
  justify-content: center;
  background-color: black;
}
@media (min-width: 700px) {
  .history-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    background-color: black;
  }
}

.history-header-container {
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  height: 136px;
  background: #262626;
  z-index: 99;
}

.history-header-title {
  margin-left: 14px;
  margin-top: 50px;
  color: white;
}

.history-search-input {
  margin-top: 14px;
  height: 32px;
  border: 0px;
  border-radius: 5px;
  margin-left: 15px;
  padding-left: 10px;
  padding-right: 10px;
  width: calc(100vw - 50px);
  color: white;
  font-size: 14px;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0.0);
  border-radius: 4px;
}

.history-list {
  margin-top: 144px;
  width: 100%;
}

.history-list-seg {
  color: white;
  font-size: 14px;
  margin-left: 14px;
  margin-top: 20px;
}

.history-list-item {
  margin: 15px;
  height: 96px;
  display: flex;
  flex-direction: row;
  background: #222222;
  border-radius: 8px;
  position: relative;
}

.history-list-item-img {
  width: 96px;
  height: 72px;
  margin: 12px;
}

.history-list-item-title {
  margin-top: 12px;
  width: 210px;
  color: white;
  font-size: 14px;
  overflow-wrap: break-word;
  height: 38px;
  overflow: hidden;
}

.history-list-item-star {
  width: 16px;
  right: 20px;
  bottom: 20px;
  position: absolute;
}

.history-item-name {
  left: 118px;
  top: 70px;
  font-size: 12px;
  color: white;
  position: absolute;
  width: 100px;
  overflow: hidden;
}

.history-item-star{
  left: 220px;
  top: 70px;
  font-size: 12px;
  color: white;
  position: absolute;
  display: flex;
  flex-direction: row;
  height: 12px;
}
.history-item-logo{
  margin-top: 2px;
  padding-right: 6px;
  width: 12px;
  height: 12px;
}