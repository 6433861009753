.pop-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
}

.pop-view {
  width: 164px;
  height: 196px;
  background-color: #20202088;
  position: fixed;
  bottom: env(safe-area-inset-bottom);
  margin-bottom: 49px;
  left: 50%; /* 左边距为 50%，使其相对于父元素水平居中 */
  transform: translateX(-50%); /* 使用 transform 进行微调，使其完全水平居中 */
  z-index: 1000;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  text-align: center;
  line-height: 34px;
}

.pop-view::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: 72px;
  bottom: -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #20202088;
}

.pop-view-btn1 {
  background-color: #0053a0;
  width: 134px;
  height: 32px;
  margin-top: 11px;
  margin-left: 15px;
  font-size: 16px;
  color: white;
  border-radius: 3px;
}

.pop-view-btn2 {
  background-color: #0053a0;
  width: 134px;
  height: 32px;
  margin-top: 4px;
  margin-left: 15px;
  font-size: 16px;
  color: white;
  border-radius: 3px;
}
