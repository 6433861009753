.chat-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0d1f2b; /* 设置背景颜色 */
  position: relative;
  justify-content: center;
  background-color: black;
  text-align: center;
  justify-content: center;
}

@media (min-width: 700px) {
  .chat-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    background-color: black;
  }
}

.chat-list {
  margin-top: 44px;
  background-color: #ffffff12;
  padding-bottom: 20px;
  border-radius: 8px;
  margin-bottom: 64px;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none;
}

.chat-list-item-l {
  display: flex;
  flex-direction: row;
  min-height: 48px;
  margin-top: 24px;
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
}

.chat-list-item-r {
  display: flex;
  flex-direction: row-reverse;
  min-height: 48px;
  margin-top: 24px;
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
}

.chat-list-time {
  margin-top: 32px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  width: 100%;
}

.chat-list-item-time {
  width: 48px;
  height: 22px;
  line-height: 22px;
  background-color: #ffffff1a;
  justify-content: center;
  border-radius: 2px;
  color: white;
}

.chat-list-item-logo {
  width: 48px;
  height: 48px;
  border-radius: 24px;
}

.chat-list-item-text-white {
  margin-left: 16px;
  background-color: white;
  text-align: left;
  border-radius: 16px 16px 16px 0px;
  color: black;
  max-width: 70%;
}

.chat-list-item-text-yellow {
  margin-right: 16px;
  background-color: #f7b500;
  text-align: left;
  border-radius: 16px 16px 0px 16px;
  color: black;
  max-width: 70%;
}

.chat-list-item-photo {
  margin-left: 16px;
  margin-right: 16px;
  width: 228px;
  height: 138px;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
}

.chat-list-item-photo-gs {
  width: 228px;
}

/* .chat-list-item-photo-gs::after {
  content: "";
  position: absolute;
  top: -100%;
  left: -100%;
  right: -100%;
  bottom: -100%;
  backdrop-filter: blur(10px);
  z-index: 1;
} */

.chat-list-item-photo-lock {
  left: 100px;
  top: 34px;
  width: 32px;
  height: 32px;
  position: absolute;
  z-index: 3;
}

.chat-list-item-photo-play {
  left: 100px;
  top: 54px;
  width: 32px;
  height: 32px;
  position: absolute;
  z-index: 3;
}

.chat-list-item-msg {
  font-size: 16px;
  padding-left: 7px;
  padding-right: 7px;
  padding-top: 15px;
  padding-bottom: 10px;
}

.chat-box {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #29282b;
  display: flex;
  flex-direction: column;
  z-index: 3;
}

.chat-input {
  left: 0;
  width: 100%;
  height: 68px;
  background-color: #29282b;
  display: flex;
  flex-direction: row;
  z-index: 3;
}

.chat-input-image {
  margin-left: 15px;
  margin-top: 22px;
  width: 24px;
  height: 24px;
}

.chat-inputbox {
  margin-top: 18px;
  padding-left: 10px;
  border-radius: 20px;
  height: 32px;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100vw - 139px);
  background-color: rgba(0, 0, 0, 0.3);
  border: none; /* 移除边框 */
  outline: none; /* 移除获取焦点时的默认边框 */
  color: white;
}

.chat-input-send {
  margin-top: 18px;
  padding-right: 0;
  width: 32px;
  height: 32px;
  margin-right: 14px;
}

.chat-list-item-photo-money {
  left: 43px;
  top: 76px;
  height: 28px;
  border-radius: 4px;
  background-color: #f7b500;
  position: absolute;
  display: flex;
  flex-direction: row;
  line-height: 28px;
  z-index: 3;
}

.chat-list-item-photo-money-coin {
  margin-top: 3px;
  margin-left: 3px;
  width: 25px;
  height: 25px;
}

.chat-list-item-photo-money-count {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.chat-list-item-photo-money-unlock {
  margin-left: 5px;
  font-size: 16px;
  color: black;
  padding-right: 10px;
}

.chat-input-emoji {
  width: 100%;
  height: 200px; /* Fixed height */
  margin-bottom: 0;
  background-color: #fff; /* Set a consistent background color */
  display: grid;
  grid-template-columns: repeat(
    8,
    1fr
  ); /* Adjust the number of columns based on your layout preference */
  gap: 5px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Enable vertical scrolling */
}

.emoji-btn {
  font-size: 24px;
  cursor: pointer;
  border: none;
  background: transparent;
}
