.termsofuse-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0d1f2b; /* 设置背景颜色 */
  position: relative;
  justify-content: center;
  background-color: black;
}

@media (min-width: 700px) {
  .termsofuse-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    background-color: black;
  }
}

.termsofuse-policy {
  margin-top: 44px;
  margin-left: 15px;
  margin-right: 15px;
  color: white;
  font-size: 12px;
}