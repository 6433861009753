.verified-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0d1f2b; /* 设置背景颜色 */
  position: relative;
  justify-content: center;
  background-color: black;
}

@media (min-width: 700px) {
  .verified-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    background-color: black;
  }
}

.verified-title {
  margin: 20px;
  margin-top: 54px;
  font-size: 14px;
  color: #bdbdbf;
}

.verified-item {
  margin-left: 15px;
  margin-right: 15px;
  height: 54px;
  font-size: 16px;
  color: white;
  line-height: 54px;
  border-bottom: 1px solid #1a191d;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
}

.verified-input {
  background-color: rgba(0, 0, 0, 0);
  border: none; /* 移除边框 */
  outline: none; /* 移除获取焦点时的默认边框 */
  margin-left: 15px;
  margin-right: 15px;
  width: 260px;
  text-align: right;
  font-size: 16px;
  color: white;
}

.verified-item-arrow {
  top: 20px;
  right: 15px;
  position: absolute;
  width: 20px;
  height: 20px;
}

.verified-item-video {
  margin-left: 15px;
  margin-right: 15px;
  height: 54px;
  font-size: 16px;
  color: white;
  line-height: 54px;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
}

.verified-item-content {
  margin-right: 40px;
  margin-top: 2px;
}

.verified-item-upload {
  width: 230px;
  height: 160px;
  background-color: #212026;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.verified-item-title {
  width: 150px;
}

.verified-item-upload-image {
  /* background-color: #3C3B3F; */
  width: 32px;
  height: 32px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
}

.verified-item-upload-txt {
  margin-top: 29px;
  width: 100%;
  font-size: 12px;
  color: #9d9d9f;
  text-align: center;
}

.verified-next {
  width: 124px;
  height: 32px;
  line-height: 32px;
  background-color: #2e2d30;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  text-align: center;
}

.verified-upload {
  width: 230px;
  height: 160px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.verified-upload-video {
  width: 230px;
  height: 160px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #2e2d30;
}
