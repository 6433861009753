.tags-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0d1f2b; /* 设置背景颜色 */
  position: relative;
  justify-content: center;
  background-color: black;
  text-align: center;
}

@media (min-width: 700px) {
  .tags-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    background-color: black;
  }
}

.tags-header {
  margin-top: 55px;
}

.tags-title {
  margin-left: 15px;
  margin-top: 16px;
  font-size: 16px;
  line-height: 16px;
  height: 17px;
  text-align: left;
  color: white;
  margin-bottom: 20px;
  white-space: nowrap;
}

.tags-list {
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 30px;
  color: white;
  align-content: flex-start;
  flex-wrap: wrap;
  /* justify-content: space-between ; */
}

.tags-choose{
  display: flex;
  flex-direction: row;
}

.tags-item-sel {
  margin-right: 15px;
  margin-bottom: 15px;
  height: 24px;
  line-height: 24px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #4091E1;
}

.tags-item {
  margin-right: 15px;
  margin-bottom: 15px;
  height: 24px;
  line-height: 24px;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #424144;
}


.tags-subtitle {
 
  margin-left: 15px;
  margin-top: 30px;
  font-size: 16px;
  line-height: 16px;
  height: 17px;
  text-align: left;
  color: white;
  margin-bottom: 20px;
  white-space: nowrap;
}