.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #0d1f2b; /* 设置背景颜色 */
}


@media (min-width: 700px) {
 
  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
  }
}

.register-title {
  margin-top: 82px;
  color: white;
  font-size: 20px;
  text-align: center;
}

.register-google-btn {
  background-color: #11354d;
  margin-top: 12px;
  margin-left: 60px;
  margin-right: 60px;
  height: 34px;
  line-height: 34px;
  border: none;
  color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin-bottom: 15px;
}

.register-google-btn-contain {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 170px;
  flex-direction: row;
}

.register-google-img {
  margin-top: 4px;
  margin-right: 5px;
  width: 25px;
  height: 25px;
}

.register-or {
  margin-top: 14px;
  color: white;
  font-size: 14px;
  text-align: center;
}

.register-email {
  margin-top: 62px;
  margin-left: 60px;
  margin-right: 60px;
  border: 0px;
  border-radius: 8px;
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #11354d;
  font-size: 14px;
  color: white;
}

.register-input {
  margin-top: 16px;
  margin-left: 60px;
  margin-right: 60px;
  border: 0px;
  border-radius: 8px;
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #11354d;
  font-size: 14px;
  color: white;
}

.register-check {
  margin-top: 12px;
  margin-left: 60px;
  margin-right: 60px;
  display: flex;
  flex-direction: row;
  line-height: 36px;
}

.register-check-box {
  padding: 12px;
  padding-left: 14px;
  padding-right: 6px;
  width: 15px;
  height: 15px;
}

.register-check-text {
  margin-left: 12px;
  color: white;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  flex-wrap: wrap;
  display:inline-block;
}

.register-check-text-privacy {
  color:#0E95E9;
  font-size: 14px;
}

.register-btn{
  margin-top: 52px;
  margin-left: 60px;
  margin-right: 60px;
  height: 34px;
  border: none;
  color: white;
  border-radius: 8px;
  background-color: #0053A0;
  font-size: 16px;
}

.register-login-btn{
  text-align: center; 
  margin-top: 16px; 
  text-decoration: none;
  color: #0E95E9;
  display: flex;
  flex-direction: row;
  justify-content: center
}

::-webkit-scrollbar {
  display: none;
}

.register-check-text {
  color: white;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  flex-wrap: wrap;
  display: inline-block;
  margin-left: 35px;
  margin-right: 35px;
  margin-top: 105px;
  text-align: left;
  max-width: 378px;
}

.register-check-text-privacy {
  color: #0e95e9;
  font-size: 14px;
}