.live-output-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  position: relative;
}

@media (min-width: 700px) {
  .live-output-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: black;
    margin-left: auto;
    margin-right: auto;
    max-width: 428px;
    position: relative;
  }
}

.liveout-gift {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.live-output-close-btn {
  position: fixed;
  top: 13px;
  right: 7px;
  font-size: 16px;
  width: 32px;
  height: 32px;
  z-index: 102;
}

.live-output-user-id {
  position: fixed;
  top: 18px;
  right: 47px;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  height: 24px;
  line-height: 24px;
  z-index: 102;
  font-size: 12px;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

.live-output-user-container{
  position: fixed;
  top: 12px;
  left: 15px;
  height: 40px;
  z-index: 102;
}

.live-output-user-info {
  position: relative;
  font-size: 16px;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
}

.live-output-header {
  position: relative;
  margin: 4px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.live-output-user-info-text {
  position: relative;
  width: 73px;
  height: 100%;
}

.live-output-user-info-text1 {
  margin-top: 3px;
}

.live-output-user-info-text2 {
  font-size: 10px;
  display: flex;
  flex-direction: row;
}

.live-output-user-info-image {
  margin: 1px;
  width: 12px;
  height: 12px;
}

.live-output-user-info-notice {
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  margin: 7px;
  height: 26px;
  line-height: 26px;
  color: black;
  font-size: 14px;
  background-color: #f7b500;
  border-radius: 12.5px;
}

.liveoutput-chatscroll-container {
  width: 272px;
  height: 193px;
  left: 15px;
  bottom: 60px;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background-color: #f7b500;
}


.live-chat-item {
  margin-top: 5px;
  margin-left: 5px;
  height: 24px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
}


.live-output-tools {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 68px;
  z-index: 105;
  display: flex;
  flex-direction: row;
  color: white;
  text-align: center;
  justify-content: space-between;
}

@media (min-width: 700px) {
 
  .live-output-tools {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 68px;
    z-index: 105;
    display: flex;
    flex-direction: row;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    max-width: 428px;
  }
}

.live-output-tools-imgs {
  display: flex;
  flex-direction: row;
  width: 158px;
  margin-right: 15px;
}

.live-output-tools-box {
  margin-left: 10px;
  margin-top: 12px;
  width: 32px;
  height: 32px;
}

.live-output-chat-box {
  margin-top: 12px;
  margin-left: 15px;
  width: 190px;
  height: 32px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
}

.live-output-chat-logo {
  height: 28px;
  width: 28px;
  margin: 2px;
  border-radius: 14px;
}

.live-output-chat-inputbox {
  margin-left: 5px;
  height: 28px;
  color: white;
  width: 140px;
  background-color: rgba(0, 0, 0, 0);
  border: none;  
  outline: none;  
}

.live-output-input-send {
  margin-left: 3px;
  margin-top: 15px;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
}

.live-output-invite1v1 {
  width: 60px;
  height: 72px;
  right: 15px;
  bottom: 100px;
  position: fixed;
}

::-webkit-scrollbar {
  display: none;
}


.live-output-pop-view {
  width: 100%;
  height: 200px;
  background-color: red;
}