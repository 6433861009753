.live-pop-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
}

.live-pop-view {
  position: fixed;
  width: 100%;
  height: 362px;
  background-color: black;
  bottom: 0;
  left: 50%; /* 左边距为 50%，使其相对于父元素水平居中 */
  transform: translateX(-50%); /* 使用 transform 进行微调，使其完全水平居中 */
  z-index: 1000;
  border-radius: 10px;
  text-align: center;
  line-height: 34px;
}
.live-pop-list {
  position: relative;
  margin-top: 0;
  width: 100%;
  height: 270px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  overflow-y: auto;
}

.live-pop-item-normal {
  width: 86px;
  height: 108px;
  margin-top: 20px;
  margin-left: 10px;
  border-width: 2px; /* 边框宽度 */
  border-radius: 10px;  
  border-style: solid; /* 边框样式 */
  border-image: linear-gradient(to right, #000, #000) 1; /* 渐变边框 */
  overflow: hidden;
}

.live-pop-item {
  width: 86px;
  height: 108px;
  margin-top: 20px;
  margin-left: 10px;
  border-width: 2px; /* 边框宽度 */
  border-style: solid; /* 边框样式 */
  border-image: linear-gradient(to right, #ff9966, #ff5e62) 1; /* 渐变边框 */
  border-radius: 10px; /* 圆角边框 */
  overflow: hidden; /* 保证内容不会溢出边框 */
}

.live-pop-bottom {
  position: absolute;
  width: 100%;
  height: 84px;
  background-color: #141414;
  bottom: 0px;
  display: flex;
  flex-direction: row;
}

.live-pop-image {
  margin-left: 23px;
  margin-top: 20px;
  width: 40px;
  height: 40px;
}

.live-pop-price {
  color: white;
  font-size: 13px;
  line-height: 13px;
  display: flex;
  flex-direction: row;
  margin-left: 29px;
  margin-right: auto;
  margin-top: 19px;
}

.live-pop-price-logo {
  width: 14px;
  height: 14px;
}

.live-pop-image-send {
  width: 68px;
  height: 32px;
  margin-left: auto;
  margin-right: 15px;
  margin-top: 16px;
}

.live-pop-left {
  display: flex;
  flex-direction: row;
}
.live-pop-money-coin {
  margin-left: 15px;
  margin-top: 16px;
  height: 26px;
  width: 26px;
}

.live-pop-money-number {
  color: white;
  font-size: 14;
  height: 26px;
  margin-left: 3px;
  margin-top: 15px;
  line-height: 26px;
}

.live-pop-money-arrow {
  margin-top: 22px;
  margin-left: 3px;
  height: 12px;
  width: 12px;
}

