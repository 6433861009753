.show-pay-alert-container {
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  text-align: center;
  z-index: 2;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 100vh;
}

@media (min-width: 700px) {
  .show-pay-alert-container {
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 428px;
    position: absolute;
    z-index: 2;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    height: 100vh;
    text-align: center;
  }
}

.show-pay-alert-bg {
  width: 270px;
  height: 246px;
  background-size: cover; /* 背景图覆盖整个元素 */
  background-position: center; /* 背景图居中显示 */
}

.show-pay-alert-main {
  position: absolute;
  width: 270px;
  height: 246px;
  background-size: cover; /* 背景图覆盖整个元素 */
  background-position: center; /* 背景图居中显示 */
}


.show-pay-alert-main-title {
  position: relative;
  margin-top: 0;
  margin-left: 67px;
  width: 116px;
  height: 28px;
  background-color: white;
  border-radius: 0 0 6px 6px ; 
  display: flex;
  flex-direction: row;
  color: #FF005D;
  line-height: 28px;
  margin-bottom: 10px;
}

.show-pay-alert-img{
  margin-left: 9px;
  margin-top: 10px;
  width: 6px;
  height: 9px;
}

.show-pay-alert-main-text {
  margin-left: 9px;
  font-size: 16px;
}

.show-pay-alert-main-input1 {
  margin-top: 20px;
  margin-left: 31px;
  margin-right: 31px;
  color:  white;
  line-height: 20px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  text-align: left;
}

.show-pay-alert-main-input2 {
  margin-top: 10px;
  margin-left: 31px;
  margin-right: 31px;
  color:  white;
  line-height: 20px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  text-align: left;
}

.show-pay-alert-main-input-txt {
  padding-left: 8px;
  height: 28px;
  line-height: 28px;
  background-color: transparent; /* 去掉背景色 */
  color:  white;
}
input::placeholder {
  color: white; /* 设置占位符颜色为红色 */
  opacity: 1; /* 确保占位符的颜色不会因为透明度而改变 */
}

.show-pay-alert-main-sure {
  width: 86px;
  height: 32px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  background-color: white;
  color: #FF005D;
  font-size: 20px;
  text-align: center;
  line-height: 32px;
  border-radius: 16px;
}