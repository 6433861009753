.share-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: black; /* 设置背景颜色 */
  position: relative;
  justify-content: center;
  background-color: black;
}

@media (min-width: 700px) {
  .share-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: black;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
  }
}

.share-item-img {
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  position: absolute;
}

.share-body {
  background-color: white;
  width: 270px;
  height: 324px;
  top: 178px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  border-radius: 8px;
}

.share-btn-body {
  width: 270px;
  height: 46px;
  top: 518px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.share-btn {
  height: 100%;
  width: 130px;
}

.share-header {
  position: absolute;
  top: 127px;
  width: 72px;
  height: 72px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  border-radius: 36px;
  border: 2px solid #fff;
}

.share-item-nickname {
  margin-top: 22px;
  font-size: 24px;
  color: black;
  width: 100%;
  text-align: center;
}

.share-item-qrcode {
  width: 180px;
  height: 180px;
  margin-top: 14px;
  margin-left: 44px;
  margin-right: auto;
}

.share-item-notice {
  margin-top: 5px;
  font-size: 11px;
  color: black;
  width: 100%;
  text-align: center;
}