.live-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
  
  @media (min-width: 700px) {
    .live-container {
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      margin-left: auto;
      margin-right: auto;
      max-width: 428px;
    }
  }
  
  .live-show {
    position: absolute;
    top: 0px;
    left: 0;
    height: 300px;
    display: flex; /* 使用 flex 布局 */
    flex-direction: row;
  }

  .live-show-remote
  {
    position: absolute;

    top: 100px;;
    width: 100%;
    height: 200px;
    z-index: 100px;
  }

  .live-show-remote-item {
    position: relative;
    display: flex; 
    flex-direction: column;
    width: 150px;
    height: 150px;
    z-index: 100px;
  }


  .live-show-info {
    position: absolute;
    top: 60px;
    height: 200px;
    color: white;
    display: flex; /* 使用 flex 布局 */
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }