.search-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0d1f2b; /* 设置背景颜色 */
  position: relative;
  justify-content: center;
  background-color: black;
}
@media (min-width: 700px) {
  .search-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    background-color: black;
  }
}

.search-header-container {
  width: 100%;
  left: 50px;
  top: 0;
  position: fixed;
  height: 44px;
  z-index: 101;
}

.search-search-input {
  margin-top: 7px;
  border: 0px;
  border-radius: 5px;
  margin-left: 0px;
  padding-left: 10px;
  padding-right: 10px;
  width: calc(100vw - 96px);
  height: 25px;
  color: white;
  font-size: 12px;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0.0);
  border-radius: 4px;

}

.search-list {
  margin-top: 44px;
  width: 100%;
}

.search-item-bg {
  
    border-radius: 8px;
  position: absolute;
  left: 0;
  top: 0;
}

.search-list-seg {
  color: white;
  font-size: 14px;
  margin-left: 14px;
  margin-top: 20px;
}

.search-list-item {
  margin: 15px;
  height: 96px;
  display: flex;
  flex-direction: row;
  background: #222222;
  border-radius: 8px;
  position: relative;
}

.search-list-item-img {
  width: 48px;
  height: 48px;
  margin: 20px;
  border-radius: 24px;
  border: 2px solid white; /* 添加 2px 宽度的白色边框 */
}

.search-list-item-word {
  display: flex;
  flex-direction: column;
}

.search-list-item-title {
  margin-top: 12px;
  width: 210px;
  color: white;
  font-size: 14px;
  overflow-wrap: break-word;
  overflow: hidden;
}

.search-list-item-content {
  margin-top: 12px;
  width: 210px;
  color: white;
  font-size: 12px;
  overflow-wrap: break-word;
  height: 38px;
  overflow: hidden;
}


.search-item-star{
  position: absolute;
  right: 16px;
  top: 32px;
  width: 54px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #F7B500;
  color: #F7B500;
}