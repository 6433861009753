.find-container {
  margin-top: 0;
  margin-left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #000;
}

@media (min-width: 700px) {
  .find-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100vh-44px);
    background-color: #000;
    margin-left: auto;
    margin-right: auto;
    max-width: 428px;
    margin-bottom: 64px;
  }
}

.find-search {
  margin-top: 63px;
  height: 34px;
  border: 0px;
  width: calc(100% - 30px);
  margin-left: 15px;
  margin-right: 15px;
  color: white;
  font-size: 14px;
  border: 1px solid white;
  background-color: black;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.find-search-input {
  width: calc(100% - 80px);
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  font-size: 14px;
  background-color: rgba(0, 0, 0, 0);
  border: none; /* 移除边框 */
  outline: none; /* 移除获取焦点时的默认边框 */
}

.find-search-btn {
  margin-top: 6px;
  margin-right: 16px;
  width: 20px;
  height: 20px;
}

.find-list {
  margin-top: 0;
  width: 100%;
  height: 100%;
}

.find-item {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 12px;
  height: 140px;
  position: relative;
  overflow: hidden;
}

.find-item-bg {
  border-radius: 8px;
}

.find-item-head {
  position: absolute;
  border-radius: 44px;
  width: 88px;
  height: 88px;
  left: 31px;
  top: 28px;
  overflow: hidden;
  border: 2px solid white;
}

.find-item-head-dot {
  position: absolute;
  width: 16px;
  height: 16px;
  left: 95px;
  top: 100px;
  border-radius: 8px;
  background-color: green;
  border: 1px solid white;
}

.dot-unable {
  background-color: #565656;
}

.find-item-name {
  position: absolute;
  left: 135px;
  top: 64px;
  font-size: 16px;
  color: white;
}

.find-item-id {
  position: absolute;
  left: 135px;
  top: 92px;
  font-size: 12px;
  color: white;
  background: #0000006e;
  padding: 6px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
}

.find-item-id-star {
  margin-left: 4px;
  width: 16px;
  height: 16px;
}

.find-item-state {
  position: absolute;
  right: 29px;
  top: 14px;
  padding: 6px;
  color: white;
  background: #0000006e;
  padding: 6px;
  border-radius: 3px;
}

.find-item-more {
  position: absolute;
  right: 29px;
  bottom: 14px;
  padding: 6px;
}

.find-item-bottom {
  width: 100%;
  height: 54px;
  margin-left: 0;
  margin-top: 0;
}
