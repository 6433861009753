.withdraw-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0d1f2b; /* 设置背景颜色 */
  position: relative;
  justify-content: center;
  background-color: black;
}

@media (min-width: 700px) {
  .withdraw-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    background-color: black;
  }
}

.withdraw-title {
  margin: 20px;
  margin-top: 122px;
  margin-bottom: 0px;
  font-size: 16px;
  color: white;
}

.withdraw-title1 {
  margin: 20px;
  margin-top: 50px;
  margin-bottom: 0px;
  font-size: 16px;
  color: white;
}

.withdraw-content {
  margin: 20px;
  margin-top: 2px;
  margin-bottom: 0px;
  font-size: 14px;
  color: white;
}

.withdraw-item {
  margin-top: 30px;
  margin-left: 15px;
  margin-right: 15px;
  height: 64px;
  font-size: 16px;
  color: white;
  background-color: #11354d;
  line-height: 54px;
  border-bottom: 1px solid #1a191d;
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
}

.withdraw-input {
  background-color: rgba(0, 0, 0, 0);
  border: none; /* 移除边框 */
  outline: none; /* 移除获取焦点时的默认边框 */
  margin-left: 15px;
  margin-right: 15px;
  width: 260px;
  text-align: left;
  font-size: 16px;
  color: white;
}
.withdraw-input-coin {
  margin: 13px;
  width: 40px;
  height: 40px;
}

.withdraw-next {
  width: 184px;
  height: 40px;
  line-height: 40px;
  background-color: #0053A0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  border-radius: 5px;
  color: white;
  font-size: 18px;
  text-align: center;
}

.withdraw-paypal {
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 150px;

}

.withdraw-column {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 16px;
}

.withdraw-tag {
  margin: 20px;
  color: white;
  font-size: 16px;
}
