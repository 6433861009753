.login-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

@media (min-width: 700px) {
 
  .login-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
  }
}
 
.login-title{
  margin-top: 122px;
  color: white;
  font-size: 20px;
  text-align: center;  
}

.login-google-btn
{
  background-color: #11354D;
  margin-top: 15px;
  margin-left: 60px;
  margin-right: 60px;
  height: 34px;
  line-height: 34px;
  border: none;
  color: white;
  border-radius: 8px;
  font-size: 14px;

}

.login-google-btn-contain {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: 200px;
  flex-direction: row;
}

.login-google-img {
  margin-top: 5px;
  margin-right: 5px;
  width: 25px;
  height: 25px;
}

.login-or{
  margin-top: 14px;
  color: white;
  font-size: 14px;
  text-align: center;  
}

.login-email-input{
  margin-top: 72px;
  margin-left: 60px;
  margin-right: 60px;
  border: 0px;
  border-radius: 8px;
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #11354D;
  color: white;
  font-size: 14px;
  /* background-color: rgba(0, 0, 0, 0.0); */
  border: none; /* 移除边框 */
  outline: none; /* 移除获取焦点时的默认边框 */
}

.login-password-input{
  margin-top: 16px;
  height: 34px;
  border: 0px;
  border-radius: 8px;
  margin-left: 60px;
  margin-right: 60px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  background-color: #11354D;
  font-size: 14px;
  /* background-color: rgba(0, 0, 0, 0.0); */
  border: none; /* 移除边框 */
  outline: none; /* 移除获取焦点时的默认边框 */
}

.login-remember {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  margin-left: 60px;
  margin-right: 60px;
}

.login-remember-check-box {
  margin-left: 2px;
  width: 20px;
  height: 20px;
}

.login-remember-check-text-remember {
  margin-left: 10px;
  color: white;
}

.login-btn{
  margin-top: 50px;
  margin-left: 60px;
  margin-right: 60px;
  height: 34px;
  color: white;
  border-radius: 8px;
  background-color: #0053A0;
  font-size: 16px;
  text-align: center;
  line-height: 34px;
}

.login-forgot-btn{
  text-align: center; 
  margin-top: 24px; 
  text-decoration: none;
  color: #0E95E9;
}

.login-register-btn{
  text-align: center; 
  margin-top: 16px; 
  text-decoration: none;
  color: #0E95E9;
  line-height: 20px;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center
}

.login-check-text {
  color: white;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  flex-wrap: wrap;
  display: inline-block;
  margin-left: 35px;
  margin-right: 35px;
  margin-top: 105px;
  text-align: left;
  max-width: 378px;
}


::-webkit-scrollbar {
  display: none;
}