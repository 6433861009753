.audience-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    position: fixed;
  }
  
  @media (min-width: 700px) {
    .audience-container {
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      margin-left: auto;
      margin-right: auto;
      max-width: 428px;
    }
  }
  
  .audience-show-remote
  {
    position: absolute;
    top: 0px;;
    width: 100%;
    height: 100vh;
  }

  .audience-show-remote-item {
    position: relative;
    display: flex; 
    flex-direction: column;
    width: 100%;
    height: 100vh;
    z-index: 100px;
  }


  .audience-show-info {
    position: absolute;
    top: 60px;

    height: 200px;
    color: white;
    display: flex; /* 使用 flex 布局 */
    flex-direction: column;
    width: 100%;
    overflow: hidden;
  }
  
  .audience-btn {
    position: absolute;
    bottom: 80px;
    left: 35px;
    right: 35px;
    font-size: 16px;
    color: black;
    border: 1px solid #fff; /* 设置边框为2像素宽的白色边框 */
    border-radius: 6px;
    height: 44px;
    display: flex; /* 使用 flex 布局 */
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    z-index: 1000;
  }
  