.library-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #2C313B;
  margin-top: 0px;
}

@media (min-width: 700px) {
  .library-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #2C313B;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    margin-top: 0px;
  }
}

.library-tabs {
  margin-top: 44px;
}

.library-list {
  margin-left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start; /* 改为flex-start以保持左对齐 */
  align-content: flex-start;
}

.library-list-item {
  height: 261px;
  position: relative;
  overflow: hidden;
  margin-bottom: 14px;
  background-color: black;
  width: 46%;
  margin-left: 2%;
  margin-right: 2%;
}

.library-list-item-img {
  margin-left: 0px;
  margin-top: 0px;
  height: 199px;
  width: 100%;
  border-radius: 6px;
}


.library-list-item-title {
  font-size: 14px;
  color: white;
  height: 16px;
  line-height: 16px;
  width: 300px;
  margin: 10px;
  overflow: hidden;
}

.library-list-item-money {
  color: #F7B500;
  font-size: 14px;
  height: 20px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
}

.library-list-item-coin {
  width: 20px;
  height: 20px;
  padding-right: 4px;
}

.library-list-item-number {
  color: white;
  font-size: 10px;
  height: 12px;
  line-height: 12px;
  position: absolute;
  bottom: 13px;
  right: 10px;
}

.library-list-item-tag {
  top: 10px;
  left: 10px;
  height: 20px;
  position: absolute;
  font-size: 14px;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0 3px 0 3px;
}

.library-list-item-share {
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  position: absolute;
}
.library-list-item-trash {
  position: absolute;
  top: 165px;
  right: 10px;
  width: 20px;
  height: 20px;
}

.library-add {
  bottom: 50px;
  left: 0;
  right: 0;
  width: 50px;
  height: 50px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
}

.library-add-upload {
  bottom: 50px;
  left: 0;
  right: 0;
  width: 50px;
  height: 50px;
  position: fixed;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
}
