.person-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0d1f2b; /* 设置背景颜色 */
  position: relative;
  justify-content: center;
  background-color: black;
}
@media (min-width: 700px) {
  .person-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    background-color: black;
  }
}

.person-header-container {
  width: 100%;
  left: 0;
  top: 0;
  height: 300px;
}

.person-header-bg {
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  height: auto;
}

.person-header-name {
  position: absolute;
  left: 99px;
  top: 142px;
  font-size: 24px;
  color: white;
}

.person-header-id {
  position: absolute;
  left: 99px;
  top: 171px;
  font-size: 14px;
  color: #efefef;
}

.person-header-des {
  position: absolute;
  left: 16px;
  top: 215px;
  font-size: 14px;
  color: #efefef;
}

.person-header-logo {
  margin-right: 6px;
  width: 20px;
}
.person-header-star {
  position: absolute;
  top: 156px;
  right: 106px;
  height: 20px;
  display: flex;
  flex-direction: row;
  color: #f7b500;
  font-size: 16px;
}

.person-header-like {
  position: absolute;
  top: 156px;
  right: 15px;
  height: 20px;
  display: flex;
  flex-direction: row;
  color: #f7b500;
  font-size: 16px;
}

.person-header-user-photo {
  left: 15px;
  top: 133px;
  position: absolute;
  width: 72px;
  height: 72px;
  background-color: yellow;
  border-radius: 36px;
}

.person-header-lnf {
  left: 0;
  top: 251px;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 50px;
}

.person-header-lnf-cell {
  margin-top: 0;
  margin-left: 15px;
  width: 24px;
  height: 24px;
}

.person-header-notice {
  padding-left: 10px;
  padding-right: 10px;
  height: 28px;
  position: absolute;
  right: 15px;
  top: 249px;
  color: white;
  font-size: 16px;
  border-radius: 14px; /* 设置圆角大小为 10px */
  border: 1px solid #ffffff; /* 设置白色边框，边框宽度为 2px */
  text-align: center;
  line-height: 28px;
}

.person-header-follow {
  padding-left: 10px;
  padding-right: 10px;
  height: 28px;
  position: absolute;
  right: 15px;
  top: 249px;
  color: #F7B500;
  font-size: 16px;
  border-radius: 14px; /* 设置圆角大小为 10px */
  border: 1px solid #F7B500; /* 设置白色边框，边框宽度为 2px */
  text-align: center;
  line-height: 28px;
}

.person-media {
  margin-top: 15px;
}

.person-list {
  margin-left: 2px;
  margin-right: 2px;
}

.person-list-item {
  margin-bottom: 1px;
  width: 100%;
  max-height: 413px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.person-list-item-img {
  margin-left: 0px;
  margin-top: 20px;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  border: 1px solid white;
  object-fit: cover; 
}

.person-list-item-right {
  display: flex;
  flex-direction: column;
  margin-right: 0px;
  width: 90%;
}

.person-list-item-title {
  font-size: 16px;
  color: white;
  height: 20px;
  margin-left: 15px;
  width: 300px;
  margin-top: 19px;
}

.person-list-item-bigpic {
  margin-top: 12px;
  margin-left: 15px;
  width: calc(100%-15px);
  height: 290px;
}

.person-list-item-number {
  color: white;
  font-size: 16px;
  height: 32px;
  width: 100%;
  display: flex;
  flex-direction: row;
  line-height: 20px;
  padding-top: 15px;
}

.person-list-item-number-logo1 {
  margin-left: 10px;
  width: 20px;
  height: 20px;
  padding-right: 5px;
}

.person-list-item-number-logo2 {
  width: 20px;
  height: 18px;
  margin-left: 50px;
  padding-right: 5px;
}

.adm-tabs-tab-active {
  color: white;
  font-size: 16px;
}

.adm-tabs-header-mask-right {
  background-color: black;
}

.adm-tabs-tab{
  color: white;
  font-size: 16px;
}

.adm-tabs-tab-line {
  background-color: white;
}

.adm-tabs-header{
  border-bottom-width: 0;
  padding-left: 60px;
  padding-right: 60px;
}