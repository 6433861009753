.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #0d1f2b; /* 设置背景颜色 */
}


@media (min-width: 700px) {
 
  .container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
  }
}

.passwordsubmit-title {
  margin-top: 122px;
  color: white;
  font-size: 20px;
  text-align: center;
  padding-bottom: 44px;
}

.passwordsubmit-input {
  margin-top: 12px;
  margin-left: 60px;
  margin-right: 60px;
  border: 0px;
  border-radius: 8px;
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #11354d;
  font-size: 14px;
  color: white;
}

.passwordsubmit-submit{
    margin-top: 80px;
    margin-left: 66px;
    margin-right: 66px;
    height: 34px;
    border: none;
    color: white;
    border-radius: 8px;
    background-color: #0053A0;
    font-size: 16px;
  }
  
  .passwordsubmit-back{
    margin-top: 24px;
    height: 34px;
    font-size: 14px;
    color: #0E95E9;
    text-align: center; 
  }

::-webkit-scrollbar {
  display: none;
}