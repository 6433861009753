.input-live-input-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  position: relative;
}

@media (min-width: 700px) {
  .input-live-input-container {
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: black;
    margin-left: auto;
    margin-right: auto;
    max-width: 428px;
    position: relative;
  }
}

.input-live-gift {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.input-live-input-visitor-number {
  position: fixed;
  top: 18px;
  right: 47px;
  font-size: 16px;
  min-width: 24px;
  height: 24px;
  z-index: 102;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 9px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  line-height: 24px;
  padding-right: 3px;
}

.input-live-input-close-btn {
  position: fixed;
  top: 18px;
  right: 14px;
  font-size: 16px;
  width: 24px;
  height: 24px;
  z-index: 102;
}

.input-live-input-visitor-header {
  margin-top: 7px;
  margin-left: 4px;
  height: 10px;
  width: 10px;
}

.input-live-input-visitor-header-number {
  padding-left: 1px;
}

.input-live-input-user-info {
  position: fixed;
  top: 12px;
  left: 15px;
  font-size: 16px;
  height: 40px;

  z-index: 102;
  font-size: 16px;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  padding-right: 10px;
}

.input-live-input-header {
  position: relative;
  margin: 4px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
}

.input-live-input-user-info-text {
  height: 100%;
}

.input-live-input-user-info-text1 {
  margin-top: 3px;
}

.input-live-input-user-info-text2 {
  font-size: 10px;
  display: flex;
  flex-direction: row;
  height: 12px;
}

.input-live-input-user-info-dot {
  margin-left: 5px;
  background-color: #26bd00;
  border-radius: 2px;
  width: 4px;
  height: 4px;
  margin-top: 4px;
}

.input-live-input-user-info-time {
  margin-left: 3px;
}

.livein-chat-container {
  width: 272px;
  height: 193px;
  left: 15px;
  bottom: 60px;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  display: none;
}

.input-live-chat-item {
  margin-top: 5px;
  margin-left: 5px;
  height: 24px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  border-radius: 20px;
}

.input-live-chat-name {
  color: #f7b500;
  border-top-left-radius: 20px; /* 左上角圆角 */
  border-bottom-left-radius: 20px; /* 左下角圆角 */
  background-color: rgba(0, 0, 0, 0.2);
  padding-left: 5px;
}
.input-live-chat-content {
  color: white;
  border-top-right-radius: 20px; /* 左上角圆角 */
  border-bottom-right-radius: 20px; /* 左下角圆角 */
  background-color: rgba(0, 0, 0, 0.2);
  padding-right: 20px;
}

::-webkit-scrollbar {
  display: none;
}

.input-live-input-chat-tools {
  left: 50%;                       /* Position the left edge of the element at the center of the viewport */
  transform: translateX(-50%); 
  position: fixed;
  bottom: 70px;
  width: 94%;
  max-width: 360px;
  height: 68px;
  z-index: 105;
  display: flex;
  flex-direction: row;
  color: white;
  text-align: center;
  justify-content: flex-start ;
}

.input-live-input-chat-tools-imgs {
  display: flex;
  flex-direction: row;
  width: 158px;
  margin-right: 15px;
}

.input-live-input-chat-tools-box {
  margin-left: 10px;
  margin-top: 12px;
  width: 32px;
  height: 32px;
}

.input-live-input-chat-chat-box {
  margin-top: 12px;
  margin-left: 3%;
  width: 94%;
  height: 40px;
  border-radius: 20px;
  background-color: #262626;
  display: flex;
  flex-direction: row;
}

.input-live-input-chat-chat-logo {
  height: 28px;
  width: 28px;
  margin: 2px;
  border-radius: 14px;
}

.input-live-input-chat-chat-inputbox {
  margin-left: 15px;
  padding-right: 15px;
  height: 40px;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0);
  border: none;  
  outline: none;  
}

.input-live-input-send {
  margin-left: 5px;
  margin-top: 15px;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
}

.input-live-main-tool {
  left: 50%;                       /* Position the left edge of the element at the center of the viewport */
  transform: translateX(-50%);     /* Shift the element left by half its own width to center it */
  width: 303px;
  bottom: 20px;
  height: 51px;
  position: fixed;
  display: flex;
  flex-direction: row;
  color: white;
  text-align: center;
  justify-content: space-between;
  z-index: 105;
}

.input-live-main-tool-camera {
  margin-top: 1.5px;
  width: 24px;
  height: 21px;
  margin-left: auto;         /* 左边距自动 */
  margin-right: auto; 
}
.input-live-main-tool-beauty {
  margin-top: 1px;
  width: 24px;
  height: 22px;
  margin-left: auto;         /* 左边距自动 */
  margin-right: auto; 
}
.input-live-main-tool-kind {
  margin-top: 0.5px;
  width: 24px;
  height: 23px;
  margin-left: auto;         /* 左边距自动 */
  margin-right: auto; 
}
.input-live-main-tool-setting {
  width: 24px;
  height: 24px;
  margin-left: auto;         /* 左边距自动 */
  margin-right: auto; 
}
.input-live-main-tool-more {
  margin-top: 10px;
  padding-left: 5px;
  width: 16px;
  height: 4px;
  padding-bottom: 12px;
  margin-left: auto;         /* 左边距自动 */
  margin-right: auto; 
}

.input-live-main-tool-text {
  margin-top: 10px;
  width: 100%;
  text-align: center;
}
