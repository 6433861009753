.live-pop-pwd-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1000;
}

.live-pop-pwd-view {
  position: fixed;
  width: 100%;
  height: 362px;
  background-color: black;
  bottom: 0;
  left: 50%; /* 左边距为 50%，使其相对于父元素水平居中 */
  transform: translateX(-50%); /* 使用 transform 进行微调，使其完全水平居中 */
  z-index: 1000;
  text-align: center;
  line-height: 34px;
  background-color: white;
}

.live-pop-pwd-title {
  font-size: 16px;
  color: black;
  margin-top: 15px;
  font-weight: 800;
}

.live-pop-pwd-close-btn {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 25px;
  height: 25px;
}

.live-pop-pwd {
  position: relative;
  margin-top: 20px;
  width: 100%;
  height: 100px;
}
