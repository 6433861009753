.app-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: black;
}

.body {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  margin: 0;
  padding: 0;
  
}

.bottom {
  background-color: #020105;
  bottom: 0;
  padding-bottom: constant( safe-area-inset-bottom); /* 兼容 iOS < 11.2 */ 
  padding-bottom: env( safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  position: fixed;
  width: 100vw;
  z-index: 1000;
}