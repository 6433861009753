.demo-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0d1f2b; /* 设置背景颜色 */
  position: relative;
  justify-content: center;
  background-color: black;
}

@media (min-width: 700px) {
  .demo-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #0d1f2b;
    max-width: 428px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    justify-content: center;
    background-color: black;
  }
}

.demo-list {
  margin-top: 44px;
  background-color: #29282b;

  padding-bottom: 20px;
  border-radius: 8px;
}

.demo-list-item {
  display: flex;
  flex-direction: row;
  height: 48px;
  margin-top: 24px;
  position: relative;
  margin-left: 15px;
  margin-right: 15px;
}

.demo-list-item-logo {
  width: 48px;
  height: 48px;
}

.demo-list-item-text {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
}

.demo-list-item-title {
  font-size: 16px;
  color: white;
}

.demo-list-item-content {
  font-size: 13px;
  color: white;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.demo-list-item-content-time {
  position: absolute;
  right: 2px;
  top: 30px;
}
